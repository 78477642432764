import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import ModalComponent from '../../components/Modal';
import fecthMethodRequest from '../../config/service';
import { Prompt } from 'react-router-dom'
import CampaignForm, { CampaignFormInitialData } from '../../components/CreateCampaign/CampaignForm'
import CampaignSummary from '../../components/CreateCampaign/CampaignSummary'
import TnCComponent from './TnC'
import {
  createCampaignAction,
  editCampaignAction,
  setCampaignForEditAction,
  getCurrentCampaignCountAction,
  getEntityForSelectAction,
  setUserAction,
  getProjectionsForUserAction,
  getProjectionsForUserActionTrain,
  getEntityDetails,
  getCampaignNameAction,
  getManageCreativeAction,
  setPreviewDataAction,
  getEntityAction,
  saveCsvAction,
  saveinitialValuesAction,
  settingsAction,
  clearCampaignTemplateData,
  saveTabData,
  updateUserProfileAction,
  updateUserProfileActionWithoutBranch,
  loadGuestUserData,
  loadBrandData,
  loginAction,
  setSettingsData,
  loginActionForTrainComponent,
  getLanguageDataAction,
  getBrandGroupWiseAction
} from '../../redux/actions'
import Icon from '@material-ui/core/Icon'
import { Redirect } from "react-router-dom";
import {
  success as successNotificationAction,
  error as errorNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config'
import config from "../../config/config";
import Loader from './../../components/Loader'
//session expiry modal
import SessionExpiryModal from './../../components/SessionExpiryModal';
import TrainComponent from '../../components/OnBorading/index';
import KrowdLogo from '../../assets/images/krowd_logo_login.png';
import { GetSessionValue, DeleteSessionValue } from "../../utils/sessionStorage";
import {
  browserName, osName, osVersion, deviceType
} from "react-device-detect";
const publicIp = require('public-ip');

const imgStyle = {
  'display': 'block',
  'height': "3rem",
  'textAlign': "center",
}
const imgStyleForTrain = {
  'display': 'block',
  'height': "3rem",
  'textAlign': "center",
  "marginTop": '10px'
}
class CreateCampaign extends Component {
  state = {
    campaignFormData: CampaignFormInitialData,
    showTCModal: false,
    createCount: 0,
    merchantIds: [],
    formType: 'add',
    sessionExpiryModal: false,
    loginRedirect: false,
    merchantsValid: false,
    documents: [],
    previewData: '',
    merchantIdList: '',
    percentageData: '',
    formValues: '',
    languageData: '',
    selectedMerchants: [],
    previewRoute: false,
    isLoading: false,
    isFirstLoginModal: false,
    branchRoute: false,
    submitType: 'create',
    summaryValues: {},
    isApproved: false,
    branchesPendingModal: false,
    trainComponent: false,
    userExist: true,
    errorExist: false,
    errorMsgText: '',
    saveTempCampaignData: '',
    weekDaysArray: [
      {
        "label": 'MON',
        "value": 'MON',
        "selected": true,
      },
      {
        "label": 'TUE',
        "value": 'TUE',
        "selected": true,
      },
      {
        "label": 'WED',
        "value": 'WED',
        "selected": true,
      },
      {
        "label": 'THU',
        "value": 'THU',
        "selected": true,
      },
      {
        "label": 'FRI',
        "value": 'FRI',
        "selected": true,
      },
      {
        "label": 'SAT',
        "value": 'SAT',
        "selected": false,
      },
      {
        "label": 'SUN',
        "value": 'SUN',
        "selected": false,
      },
    ],
    selectedDays: [
      {
        "label": 'MON',
        "value": 'MON',
      },
      {
        "label": 'TUE',
        "value": 'TUE',
      },
      {
        "label": 'WED',
        "value": 'WED',
      },
      {
        "label": 'THU',
        "value": 'THU',
      },
      {
        "label": 'FRI',
        "value": 'FRI',
      },
    ],
    stepOne: false,
    userData: '',
    brandData: '',
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
    allBranch: true,
    trainComponentMerchantIds: [],
    isCampaignCreated: false,
    settingsState: '',
    deviceInfo: {},
    groupBrandList: [],
    selectedGroupBrands: []
  }
  constructor(props) {
    super(props)
    let { campaignForEdit, previewData } = props
    console.log("campaignForEdit    :"+campaignForEdit)
    if (campaignForEdit || (props && props.location && props.location.isPreview)) {
      this.state.formType = 'edit'
      let editableData = {}
      if (campaignForEdit) {
        editableData = campaignForEdit
      } else {
        editableData = previewData
      }
      let campaignFormData = { ...CampaignFormInitialData }
      campaignFormData._id = editableData._id
      campaignFormData.name = editableData.name
      campaignFormData.customerType = editableData.customerType
      campaignFormData.discountType = editableData.discountType || 'dp'
      campaignFormData.successFee = editableData.successFee
      campaignFormData.suggestions = editableData.suggestions
      if (editableData.start_date) {
        campaignFormData.start_date = new Date(editableData.start_date)
      } else if (editableData.openingTime) {
        campaignFormData.start_date = new Date(editableData.openingTime)
      }
      campaignFormData.merchantId = editableData.merchantId
      if (editableData.budget) {
        campaignFormData.budget = editableData.budget;
        campaignFormData.budgetType = 'budget'
      } else {
        campaignFormData.budgetType = 'na'
      }
      switch (editableData.discountType) {
        case 'dp': {
          if (editableData.discountPercent)
            campaignFormData.discountPercent = editableData.discountPercent
          break;
        }
        case 'dv': {
          campaignFormData.discountAmount = editableData.discountAmount
          campaignFormData.discountValue = editableData.discountValue
          break;
        }
        case 'ss': {
          campaignFormData.discountSpreadAmount = editableData.discountSpreadAmount
          campaignFormData.discountSpreadValue = editableData.discountSpreadValue
          break;
        }
        default: { }
      }
      if (editableData.customerType == 'existing') {
        campaignFormData.lastTransaction = editableData.lastTransaction
      }
      this.state.campaignFormData = campaignFormData
    }
    this.setDeviceInfo()
  }
  campaignSummaryData = null

  componentDidMount = async () => {
    let {
      campaignForEdit,
      history,
      getCurrentCampaignCount,
      user,
      currentCampaignCount,
      getEntityForSelect,
      getProjectionsForUser,
      getDetails,
      getCampaignName,
      getManageCreative,
      location,
      previewData,
      match,
      getEntityData,
      saveCsv,
      saveInitialValues,
      getSettingsData,
      clearTemplateData,
      setTabs,
      errorNotification,
      loadGuestData,
      loadSettingsData,
      loadBrandsData,
      getLanguageData
    } = this.props
    let userData
    await getSettingsData({}, async (res) => {
      await loadSettingsData(res)
      await this.setState({
        settingsState: res
      })
    })
    this.props.getLanguageData((res) => {
      return res
    })

    // if (this.props.match.params.user) {
    //   let checkurl = `users/checkGuestUser?email=${this.props.match.params.user}`
    //   fecthMethodRequest('GET', checkurl).then(async (response) => {
    //     if (response && response.data.errorCode) {
    //       await this.setState({
    //         userExist: false, errorExist: true,
    //         errorMsgText: response.data.errorMessage,
    //       })
    //       console.log(response.data.errorMessage)
    //       let notification = { ...notificationOpts }
    //       notification.message = `Error : ${response.data.errorMessage}`
    //       errorNotification(notification)
    //       //To append campaign name on creation
    //     } else if (response && response.data) {
    //       if (response.data.details.stepOne) {
    //         await this.setState({ stepOne: true })
    //       }
    //       await this.setState({
    //         userExist: true,
    //         errorExist: false,
    //         errorMsgText: '',
    //       })
    //       userData = response.data.details;

    //       await SetSessionValue('loginCredentials', response.data)
    //       await sessionStorage.setItem('user', JSON.stringify(userData));
    //       if (this.state.userExist) {
    //         let sessionExpiryModal = false
    //         let sessionExpired = await sessionStorage.getItem('sessionExpired')
    //         console.log(sessionExpired)
    //         if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
    //           sessionExpiryModal = true
    //         }
    //         await this.setState({ sessionExpiryModal: sessionExpiryModal, branchRoute: false })
    //         if (sessionExpiryModal) {
    //           return
    //         }

    //         let { selectedMerchants } = this.state
    //         let userDetails = await JSON.parse(sessionStorage.getItem('user'))
    //         this.setState({ isLoading: true })
    //         if (userDetails) {


    //         }
    //         getProjectionsForUser(userDetails.brandObjId, async (res) => {
    //           loadBrandsData(res)
    //           await this.setState({ brandData: res })
    //         })
    //         getCampaignName((res, type) => {
    //           if (type == 'success') {
    //             let data = this.state.campaignFormData
    //             data.name = res.campaignName
    //             this.setState({ isApproved: res.approval, }, () => {
    //               loadGuestData(userData)
    //             })
    //             if (!response.data.details.stepOne) {
    //               this.setState({ campaignFormData: data, })
    //             } else {
    //               this.setState({
    //                 trainComponent: true

    //               })
    //             }
    //           }
    //         })
    //         if (!campaignForEdit) {

    //           //Campaign Images
    //           // getManageCreative('', (res, type) => {
    //           //   if(type=='success'){
    //           //     let documents=[]
    //           //     res.forEach((item,index)=>{
    //           //       documents.push({
    //           //       label:<div className='text-center'><img src={`${config.imgUrl}docs/${item.fileName}`} className='dropdown__img' /></div>,
    //           //         value:item.fileName
    //           //       })
    //           //     })
    //           //     this.setState({documents:documents})
    //           //   }
    //           // })
    //         }
    //         //  if(sessionExpired!='true'){
    //         //Get userdetails for setting merchantids of useer
    //         await getEntityData(null, null, {}, 'merchants', 'multiselect', async (response, pagination) => {
    //           let merchantIds = []
    //           if (response && response.length > 0 && typeof (response) == 'object') {
    //             response.forEach((item, index) => {
    //               item.value = item;
    //               item.label = item.name
    //               merchantIds.push(item)
    //             })
    //             await this.setState({ merchantIds: merchantIds })
    //           }
    //           if (merchantIds && merchantIds.length == 0) {

    //             let sessionAbout = await sessionStorage.getItem('sessionExpired')
    //             if (sessionAbout == 'false' || sessionAbout == false) {
    //               await this.setState({ isFirstLoginModal: false, sessionExpiryModal: false })
    //             } else {
    //               await this.setState({ isFirstLoginModal: false, sessionExpiryModal: false })
    //             }
    //           }
    //         })

    //         //preview campaign to edit
    //         if (!campaignForEdit && !(location && location.isPreview)) {
    //           //to clear csv data
    //           saveCsv(null)
    //           //to clear initial values
    //           saveInitialValues({
    //             sms: {
    //               validFor: 'one',
    //               'Landing Page': '',
    //               'Valid For': 'one'
    //             },
    //             email: {
    //               validFor: 'one',
    //               'landing Page': '',
    //               'Valid For': 'one'
    //             },
    //             app: {
    //               validFor: 'one',
    //               'Landing Page': '',
    //               'Valid For': 'one'
    //             },
    //           })
    //           clearTemplateData()
    //           setTabs(null)
    //           if (match.url.search('edit') !== -1) {
    //             history.push('/user/campaigns/create')
    //           } else {
    //             let m = moment()
    //             let campaignName = user && user['entity'] != null ? `${user.entity.name}${m.format('YYMM')}${currentCampaignCount + 1}` : `${m.format('YYMM')}${currentCampaignCount + 1}`
    //             // let campaignName = `${"Nike"}${m.format('YYMM')}${currentCampaignCount + 1}`
    //             // : `${m.format('YYMM')}${currentCampaignCount + 1}`

    //             let campaignFormData = { ...CampaignFormInitialData, campaignName }
    //             if (!campaignFormData.budget) {
    //               campaignFormData.budget = ''
    //               campaignFormData.budgetType = 'na'
    //             } else {
    //               campaignFormData.budgetType = 'budget'
    //             }
    //           }
    //         }
    //         //to check the preview type either for new campaign or alreaduy existed campaign
    //         let url = ''
    //         let urlArray = match.url.split('/')
    //         if (urlArray[3] == 'edit') {
    //           url = 'edit'
    //         } else if (urlArray[3] == 'create') {
    //           url = 'create'
    //         }
    //         let merchantIds = this.state.merchantIds
    //         if (!campaignForEdit && !(location && location.isPreview)) {
    //           if (merchantIds) {
    //             merchantIds.forEach((item, index) => {
    //               selectedMerchants.push(item.value)
    //             })
    //             await this.setState({ selectedMerchants: selectedMerchants })
    //           }
    //           //if preview campaign is from edit it enters into this loop
    //         } else if ((campaignForEdit) && url == 'edit') {
    //           if (campaignForEdit) {
    //             let campaignMerchantIds = campaignForEdit.merchantIds
    //             let matchedMerchants = []
    //             if (campaignMerchantIds) {
    //               for (let i = 0; i < merchantIds.length; i++) {
    //                 for (let j = 0; j < campaignMerchantIds.length; j++) {
    //                   if (merchantIds[i].merchantId == campaignMerchantIds[j]) {
    //                     matchedMerchants.push(merchantIds[i])
    //                   }
    //                 }
    //               }
    //               this.setState({ selectedMerchants: matchedMerchants })
    //             }
    //           }

    //           //if preview campaign is from create it enters into this loop
    //         } else if (((location && location.isPreview)) && url == 'edit') {
    //           if (previewData && previewData.name) {
    //             let campaignMerchantIds = previewData.merchantIds
    //             let matchedMerchants = []
    //             if (campaignMerchantIds) {
    //               for (let i = 0; i < merchantIds.length; i++) {
    //                 for (let j = 0; j < campaignMerchantIds.length; j++) {
    //                   if (merchantIds[i].merchantId == campaignMerchantIds[j]) {
    //                     matchedMerchants.push(merchantIds[i])
    //                   }
    //                 }
    //               }
    //               this.setState({ selectedMerchants: matchedMerchants })
    //             }
    //           }

    //           //if preview campaign is from create it enters into this loop
    //         } else if (location && location.isPreview && url == 'create') {
    //           //for datahandling for backeend we check here
    //           if (previewData.type && previewData.type == 'All') {
    //             if (merchantIds) {
    //               merchantIds.forEach((item, index) => {
    //                 selectedMerchants.push(item.value)
    //               })
    //               await this.setState({ selectedMerchants: selectedMerchants })
    //             }
    //             // 
    //           } else {
    //             let previewMerchantIds = previewData.merchantIds
    //             let matchedMerchants = []
    //             if (previewMerchantIds) {
    //               for (let i = 0; i < merchantIds.length; i++) {
    //                 for (let j = 0; j < previewMerchantIds.length; j++) {
    //                   if (merchantIds[i].merchantId == previewMerchantIds[j]) {
    //                     matchedMerchants.push(merchantIds[i])
    //                   }
    //                 }
    //               }
    //               this.setState({ selectedMerchants: matchedMerchants })
    //             }
    //           }
    //         }
    //         this.setState({ isLoading: false })
    //       }
    //       if (response.data.details.stepOne) {
    //         let detailsData = response.data.details
    //         let campaignFormData = this.state.campaignFormData
    //         let weekDays = detailsData['stepOneData']['weekDays']
    //         campaignFormData.name = detailsData['stepOneData']['name']
    //         campaignFormData.customerType = detailsData['stepOneData']['customerType']
    //         campaignFormData.discountType = detailsData['stepOneData']['discountType'] || 'dp'
    //         campaignFormData.successFee = detailsData['stepOneData']['successFee']
    //         campaignFormData.suggestions = detailsData['stepOneData']['suggestions']
    //         if (detailsData['stepOneData']['start_date']) {
    //           campaignFormData.start_date = new Date(detailsData['stepOneData']['start_date'])
    //         } else if (detailsData['stepOneData']['openingTime']) {
    //           campaignFormData.start_date = new Date(detailsData['stepOneData']['openingTime'])
    //         }
    //         if (detailsData['stepOneData']['start_time']) {
    //           campaignFormData.start_time = new Date(detailsData['stepOneData']['start_time'])
    //         }
    //         if (detailsData['stepOneData']['end_time']) {
    //           campaignFormData.end_time = new Date(detailsData['stepOneData']['end_time'])
    //         }
    //         if (detailsData['stepOneData']['budget']) {
    //           campaignFormData.budget = detailsData['stepOneData']['budget'];
    //           campaignFormData.budgetType = 'budget'
    //         } else {
    //           campaignFormData.budgetType = 'na'
    //         }
    //         switch (detailsData['stepOneData']['discountType']) {
    //           case 'dp': {
    //             if (detailsData['stepOneData']['discountPercent'])
    //               campaignFormData.discountPercent = detailsData['stepOneData']['discountPercent']
    //             break;
    //           }
    //           case 'dv': {
    //             campaignFormData.discountAmount = detailsData['stepOneData']['discountAmount']
    //             campaignFormData.discountValue = detailsData['stepOneData']['discountValue']
    //             break;
    //           }
    //           case 'ss': {
    //             campaignFormData.discountSpreadAmount = detailsData['stepOneData']['discountSpreadAmount']
    //             campaignFormData.discountSpreadValue = detailsData['stepOneData']['discountSpreadValue']
    //             break;
    //           }
    //           default: { }
    //         }
    //         if (detailsData['stepOneData']['customerType'] == 'existing') {
    //           campaignFormData.lastTransaction = detailsData['stepOneData']['lastTransaction']
    //         }

    //         await this.setState({
    //           campaignFormData: campaignFormData, weekDaysArray: weekDays,
    //           stepOne: true,
    //           formValues: detailsData['stepOneData'],
    //         })
    //       } else {
    //         await this.setState({
    //           stepOne: false
    //         })
    //       }

    //       //To append campaign name on creation
    //     } else {
    //       sessionStorage.removeItem('user')
    //       await this.setState({ userExist: false })
    //     }
    //   }).catch((err) => {
    //     return err
    //   })
    // }

    userData = GetSessionValue('user');

    if (userData.stepOne) {
      await this.setState({ stepOne: false })
    }
    let sessionExpiryModal = false
    let sessionExpired = await GetSessionValue('sessionExpired')
    console.log(sessionExpired)
    if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
      sessionExpiryModal = true
    }
    await this.setState({ sessionExpiryModal: sessionExpiryModal, branchRoute: false })
    if (sessionExpiryModal) {
      return
    }
    if (this.state.userExist && userData && !sessionExpiryModal) {
      let { selectedMerchants } = this.state;
      let userDetails = await GetSessionValue('user');
      this.setState({ isLoading: true });

      if (userDetails.role !== "groupAdmin") {
        getProjectionsForUser(userDetails.brandObjId, (res) => {
          this.setState({ brandData: res }, () => {
            loadBrandsData(res)
          });
        })
      } else if (userData.role === "groupAdmin") {
        this.props.getBrandGroupWise(userData.brandId, (res) => {
          this.setState({ groupBrandList: res, selectedGroupBrands: res });
          if (res && res.length > 0) {
            let brandObjId = res[0]._id;
            getProjectionsForUser(brandObjId, (res) => {
              this.setState({ brandData: res }, () => {
                loadBrandsData(res)
              });
            })
          }
        })
      }

      getCampaignName((res, type) => {
        if (type == 'success') {
          let data = this.state.campaignFormData
          data.name = res.campaignName
          this.setState({ isApproved: res.approval, }, () => {
            loadGuestData(userData)
          })
          if (!userData.stepOne) {
            this.setState({ campaignFormData: data, })
          } else {
            this.setState({
              trainComponent: false

            })
          }
        }
      })
      if (!campaignForEdit) {

        //Campaign Images
        // getManageCreative('', (res, type) => {
        //   if(type=='success'){
        //     let documents=[]
        //     res.forEach((item,index)=>{
        //       documents.push({
        //       label:<div className='text-center'><img src={`${config.imgUrl}docs/${item.fileName}`} className='dropdown__img' /></div>,
        //         value:item.fileName
        //       })
        //     })
        //     this.setState({documents:documents})
        //   }
        // })
      }
      //  if(sessionExpired!='true'){
      //Get userdetails for setting merchantids of useer
      await getEntityData(null, null, {}, 'merchants', 'multiselect', async (response, pagination) => {
        let merchantIds = []
        if (response && response.length > 0 && typeof (response) == 'object') {
          response.forEach((item, index) => {
            item.value = item;
            item.label = item.name
            merchantIds.push(item)
          })
          await this.setState({ merchantIds: merchantIds })
        }
        if (merchantIds && merchantIds.length == 0) {

          let sessionAbout = await GetSessionValue('sessionExpired')
          if (sessionAbout == 'false' || sessionAbout == false) {
            await this.setState({ isFirstLoginModal: false, sessionExpiryModal: false })
          } else {
            await this.setState({ isFirstLoginModal: false, sessionExpiryModal: false })
          }
        }
      })

      //preview campaign to edit
      if (!campaignForEdit && !(location && location.isPreview)) {
        //to clear csv data
        saveCsv(null)
        //to clear initial values
        saveInitialValues({
          sms: {
            validFor: 'one',
            'Landing Page': '',
            'Valid For': 'one'
          },
          email: {
            validFor: 'one',
            'landing Page': '',
            'Valid For': 'one'
          },
          app: {
            validFor: 'one',
            'Landing Page': '',
            'Valid For': 'one'
          },
        })
        clearTemplateData()
        setTabs(null)
        if (match.url.search('edit') !== -1) {
          history.push('/user/campaigns/create')
        } else {
          let m = moment()
          let campaignName = user && user['entity'] != null ? `${user.entity.name}${m.format('YYMM')}${currentCampaignCount + 1}` : `${m.format('YYMM')}${currentCampaignCount + 1}`
          // let campaignName = `${"Nike"}${m.format('YYMM')}${currentCampaignCount + 1}`
          // : `${m.format('YYMM')}${currentCampaignCount + 1}`

          let campaignFormData = { ...CampaignFormInitialData, campaignName }
          if (!campaignFormData.budget) {
            campaignFormData.budget = ''
            campaignFormData.budgetType = 'na'
          } else {
            campaignFormData.budgetType = 'budget'
          }
        }
      }
      //to check the preview type either for new campaign or alreaduy existed campaign
      let url = ''
      let urlArray = match.url.split('/')
      if (urlArray[3] == 'edit') {
        url = 'edit'
      } else if (urlArray[3] == 'create') {
        url = 'create'
      }
      let merchantIds = this.state.merchantIds
      if (!campaignForEdit && !(location && location.isPreview)) {
        if (merchantIds) {
          merchantIds.forEach((item, index) => {
            selectedMerchants.push(item.value)
          })
          await this.setState({ selectedMerchants: selectedMerchants })
        }
        //if preview campaign is from edit it enters into this loop
      } else if ((campaignForEdit) && url == 'edit') {
        if (campaignForEdit) {
          let campaignMerchantIds = campaignForEdit.merchantIds
          let matchedMerchants = []
          if (campaignMerchantIds) {
            for (let i = 0; i < merchantIds.length; i++) {
              for (let j = 0; j < campaignMerchantIds.length; j++) {
                if (merchantIds[i].merchantId == campaignMerchantIds[j]) {
                  matchedMerchants.push(merchantIds[i])
                }
              }
            }
            this.setState({ selectedMerchants: matchedMerchants })
          }
        }

        //if preview campaign is from create it enters into this loop
      } else if (((location && location.isPreview)) && url == 'edit') {
        if (previewData && previewData.name) {
          let campaignMerchantIds = previewData.merchantIds
          let matchedMerchants = []
          if (campaignMerchantIds) {
            for (let i = 0; i < merchantIds.length; i++) {
              for (let j = 0; j < campaignMerchantIds.length; j++) {
                if (merchantIds[i].merchantId == campaignMerchantIds[j]) {
                  matchedMerchants.push(merchantIds[i])
                }
              }
            }
            this.setState({ selectedMerchants: matchedMerchants })
          }
        }

        //if preview campaign is from create it enters into this loop
      } else if (location && location.isPreview && url == 'create') {
        //for datahandling for backeend we check here
        if (previewData.type && previewData.type == 'All') {
          if (merchantIds) {
            merchantIds.forEach((item, index) => {
              selectedMerchants.push(item.value)
            })
            await this.setState({ selectedMerchants: selectedMerchants })
          }
          // 
        } else {
          let previewMerchantIds = previewData.merchantIds
          let matchedMerchants = []
          if (previewMerchantIds) {
            for (let i = 0; i < merchantIds.length; i++) {
              for (let j = 0; j < previewMerchantIds.length; j++) {
                if (merchantIds[i].merchantId == previewMerchantIds[j]) {
                  matchedMerchants.push(merchantIds[i])
                }
              }
            }
            this.setState({ selectedMerchants: matchedMerchants })
          }
        }
      }
      this.setState({ isLoading: false })

    } else {
      history.push('/login')
    }
    if (userData.stepOne) {
      let detailsData = userData
      let campaignFormData = this.state.campaignFormData
      let weekDays = detailsData['stepOneData']['weekDays']
      campaignFormData.name = detailsData['stepOneData']['name']
      campaignFormData.customerType = detailsData['stepOneData']['customerType']
      campaignFormData.discountType = detailsData['stepOneData']['discountType'] || 'dp'
      campaignFormData.successFee = detailsData['stepOneData']['successFee']
      campaignFormData.suggestions = detailsData['stepOneData']['suggestions']
      if (detailsData['stepOneData']['start_date']) {
        campaignFormData.start_date = new Date(detailsData['stepOneData']['start_date'])
      } else if (detailsData['stepOneData']['openingTime']) {
        campaignFormData.start_date = new Date(detailsData['stepOneData']['openingTime'])
      }
      if (detailsData['stepOneData']['start_time']) {
        campaignFormData.start_time = new Date(detailsData['stepOneData']['start_time'])
      }
      if (detailsData['stepOneData']['end_time']) {
        campaignFormData.end_time = new Date(detailsData['stepOneData']['end_time'])
      }
      if (detailsData['stepOneData']['budget']) {
        campaignFormData.budget = detailsData['stepOneData']['budget'];
        campaignFormData.budgetType = 'budget'
      } else {
        campaignFormData.budgetType = 'na'
      }
      switch (detailsData['stepOneData']['discountType']) {
        case 'dp': {
          if (detailsData['stepOneData']['discountPercent'])
            campaignFormData.discountPercent = detailsData['stepOneData']['discountPercent']
          break;
        }
        case 'dv': {
          campaignFormData.discountAmount = detailsData['stepOneData']['discountAmount']
          campaignFormData.discountValue = detailsData['stepOneData']['discountValue']
          break;
        }
        case 'ss': {
          campaignFormData.discountSpreadAmount = detailsData['stepOneData']['discountSpreadAmount']
          campaignFormData.discountSpreadValue = detailsData['stepOneData']['discountSpreadValue']
          break;
        }
        default: { }
      }
      if (detailsData['stepOneData']['customerType'] == 'existing') {
        campaignFormData.lastTransaction = detailsData['stepOneData']['lastTransaction']
      }

      await this.setState({
        campaignFormData: campaignFormData, weekDaysArray: weekDays,
        stepOne: false,
        formValues: detailsData['stepOneData'],
      })
    } else {
      await this.setState({
        stepOne: false
      })
    }

  }

  componentDidUpdate(prevProps) {
    let {
      setCampaignForEdit,
      user,
      currentCampaignCount,
      getProjectionsForUser
    } = this.props
    if (this.props.match.url !== prevProps.match.url) {
      setCampaignForEdit(null)
    }
    if (!this.props.campaignForEdit
      && (prevProps.campaignForEdit !== this.props.campaignForEdit
        || prevProps.currentCampaignCount !== currentCampaignCount)) {
      let m = moment()
      let campaignFormData = { ...CampaignFormInitialData, campaignName: `${user && user.entity && user.entity.name ? user.entity.name : ''}${m.format('YYMM')}${currentCampaignCount + 1}` }
    }
  }

  componentWillUnmount() {
    let { setCampaignForEdit } = this.props
    setCampaignForEdit(null)
  }

  onChangeListener = (oldCampaignFormData, newCampaignFormData) => {
    if (oldCampaignFormData.values !== newCampaignFormData.values)
      this.setState({ campaignFormData: newCampaignFormData.values })
  }

  onCampaignSummaryData = (data) => {
    this.campaignSummaryData = data
  }

  formikRef = (e) => {
    this.formik = e
  }

  submitCreateCampaign = (type, summaryData) => {
    console.log('typeeee', type)
    if (this.formik)
      this.formik.submitForm()
    this.setState({ submitType: type, summaryValues: summaryData, })
  }

  handleTrainComponentMerchantIds = async (trainComponentMerchantIds) => {
    await this.setState({
      trainComponentMerchantIds: trainComponentMerchantIds,
      selectedMerchants: trainComponentMerchantIds
    })
  }

  getDataForCampaignSubmit = (campaignFormData) => {
    let {
      customerType,
      lastTransaction,
      name,
      start_date,
      successFee,
      start_time,
      end_time,
      discountType,
      discountPercent,
      discountAmount,
      discountValue,
      discountSpreadAmount,
      discountSpreadValue,
      brand_id,
      brandId,
      suggestions,
      spread_avg_spend,
      recurring
    } = campaignFormData
    let { campaignForEdit } = this.props
    let currentBid = {
      name: name,
      // customerType: customerType === "existing" ? 'existing' : customerType === "all" ? 'all' : 'new',
      customerType: customerType,
      openingTime: moment(start_date).startOf('month').format('YYYY-MM-DD[T]HH:mm:ss'),
      closingTime: moment(start_date).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss'),
      name,
      campaign_status: 'future',
      successFee: parseInt(successFee),
      discountType,
      weekDays: this.state.weekDaysArray,
      start_time: start_time,
      end_time: end_time,
      suggestions,
      recurring
    }
    switch (discountType) {
      case 'dp': {
        currentBid['discountPercent'] = parseInt(discountPercent)
        break;
      }
      case 'dv': {
        currentBid['discountAmount'] = parseFloat(discountAmount)
        currentBid['discountValue'] = parseFloat(discountValue)
        break
      }
      case 'ss': {
        currentBid['discountSpreadAmount'] = parseInt(discountSpreadAmount)
        currentBid['discountSpreadValue'] = parseInt(discountSpreadValue)
        currentBid['spreadAvgSpend'] = parseFloat(spread_avg_spend)
        break
      }
      default: { }
    }
    if (campaignForEdit) {
      currentBid._id = campaignForEdit._id
    }
    if (customerType === 'existing') {
      currentBid.lastTransaction = parseInt(lastTransaction);
    } else {
      currentBid.lastTransaction = 'NULL';
    }
    if (brand_id)
      currentBid.brand_id = brand_id.id
    if (brandId)
      currentBid.brandId = brandId
    if (discountType)
      currentBid.discountType = discountType
    return currentBid;
  }

  getNotificationMessage = data => {
    let { campaignForEdit } = this.props;
    if (data._id) {
      return `Campaign "${campaignForEdit.name}" updated successfully`
    } else {
      return `Campaign "${data.name}" has been created successfully`
    }
  }

  handleSuccessRedirect = async () => {
    let {
      history,
      user
    } = this.props
    await this.setState({
      isCampaignCreated: true
    })
    history.push('/user/campaigns/future')
  }

  //handle action to show preview or direct submit
  handleAction = (values) => {
    let { submitType, isApproved } = this.state
    // if (!isApproved) {
    if (submitType == 'create') {
      this.setShowTCModal(values)
    } else {
      this.previewValues(values)
    }
    // } else {
    //   this.setState({ branchesPendingModal: true })
    // }

  }

  onSubmit = (values, actions) => {
    let {
      createCampaign,
      successNotification,
      errorNotification,
      editCampaign, user } = this.props;
    let { summaryValues, deviceInfo } = this.state
    let campaignData = values;
    let campaignSummaryData = this.campaignSummaryData;
    let data = this.getDataForCampaignSubmit(campaignData, campaignSummaryData)
    let executeAction = data._id ? editCampaign : createCampaign;
    // if (values && values.type) {
    //   data.type = values.type
    // }
    if (values && this.state.trainComponentMerchantIds && this.state.trainComponentMerchantIds.length > 0) {
      let branches = this.props.branches;
      if (branches && branches['items'] && branches['items'].length > 0) {
        let items = branches['items'];
        let merchantIds = [];
        let selectedMerchants = this.state.trainComponentMerchantIds;
        if (selectedMerchants && items && (items.length === selectedMerchants.length)) {
          data.type = 'All'
        } else {
          for (let i = 0; i < items.length; i++) {
            for (let j = 0; j < selectedMerchants.length; j++) {
              if (selectedMerchants[j] === items[i]['_id'] && items[i]['merchantId']) {
                merchantIds.push(items[i]['merchantId']);
                break;
              }
            }
          }
          data['merchantIds'] = merchantIds
        }
      }
    } else {
      data.type = 'All'
    }
    data = { ...summaryValues, ...data }
    data['allBranch'] = this.state.allBranch;
    if (data._id &&
      !data.suggestions) {
      delete data['suggestions'];

    }
    delete data['campaign_status'];
    data.processType = "Merchant Onboarding"
    data.deviceInfo = deviceInfo;

    if(data.recurring == "Yes") {
      data.recurring = true;
    } else if(data.recurring == "No") {
      data.recurring = false;
    }
    
    executeAction(data, (res, type) => {
      if (type == 'success' || res.indexOf("Campaign is already created") != -1) {
        // if (!this.props.user.isTAndCAccepted) {
        this.props.updateUserWithoutBranch({
          isTAndCAccepted: true,
          proceesCompleted: true,
          guestUserFlag: false,
          step: "password",
          brandId: user.brandId,
        })
        this.setState({
          isCampaignCreated: true
        })
        let userData = {
          'password': values['password'],
          'email': values['email'],
          "entityType": "user",
          'deviceInfo': values['deviceInfo']
        }
        this.props.login(userData, true)
        this.props.history.push('/user/campaigns/future')
        // }
        // this.handleSuccessRedirect()
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = this.getNotificationMessage(data)
          successNotification(notification)
        })
        return;
      }
      let error = res ? res : ''
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = `Error : ${error}`
        errorNotification(notification)
      })
    })
  }

  setShowTCModal = (values) => {
    let { user } = this.props;
    console.log(user, 'user');

    let percentageData = this && this.campaignSummaryRef && this.campaignSummaryRef.state && this.campaignSummaryRef.state.percentageData ? this.campaignFormRef.state.percentageData : {}

    values.openingTime = moment(values.start_date).startOf('month').format('YYYY-MM-DD[T]HH:mm:ss');
    values.closingTime = moment(values.start_date).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss');
    
    if (values && values.name) {
      this.setState({ formValues: values })

    }
    if (user && user.isTAndCAccepted) {
      // this.onSubmit(values);
      values['weekDays'] = this.state.weekDaysArray;
      this.props.updateUserWithoutBranch({
        stepOne: true,
        step: 'campaign',
        stepOneData: values,
        brandId: user.brandId,
        deviceInfo: this.state.deviceInfo
      })
      this.setState({ trainComponent: true })
    } else {
      this.setState({ showTCModal: true })
    }
    // if(loggedIncredentials && loggedIncredentials)

  }

  handleTCResponse = () => {
    let { user } = this.props;
    let { formValues, deviceInfo } = this.state
    formValues['weekDays'] = this.state.weekDaysArray;
    this.props.updateUserWithoutBranch({
      stepOne: true,
      isTAndCAccepted: true,
      stepOneData: formValues,
      brandId: user.brandId,
      step: 'campaign',
      deviceInfo: deviceInfo
    })
    this.setState({ showTCModal: false, showPreviewModal: false, trainComponent: true }, () => {
      // this.onSubmit(formValues)

    })
  }


  handleWeekDayArray = async (weekIndex, label) => {
    let weekDays = this.state.weekDaysArray;
    let selectedDays = this.state.selectedDays;
    let selectedDaysFlag = false, sindex = -1;
    for (let i = 0; i < selectedDays.length; i++) {
      selectedDaysFlag = false;
      if (selectedDays[i]['label'] === label) {
        selectedDaysFlag = true;
        sindex = i;
        break;
      } else {
        selectedDaysFlag = false;
      }
    }
    weekDays[weekIndex]['selected'] = !weekDays[weekIndex]['selected'];
    if (!selectedDaysFlag) {
      selectedDays.push({
        "label": label,
        "value": label
      })
    } else {
      if (sindex != -1) {
        selectedDays.splice(sindex, 1)
      }
    }
    await this.setState({ weekDaysArray: weekDays, selectedDays: selectedDays })

  }
  onCloseTCModal = () => {
    this.setState({ showTCModal: false });
  }

  onClosePreviewModal = () => {
    this.setState({ showPreviewModal: false });
  }

  sessionExpired = () => {
    DeleteSessionValue('user')
    this.setState({ loginRedirect: true, sessionExpired: true })
    setTimeout(() => this.setState({ loginRedirect: true, sessionExpired: true }), 1000)
  }

  onSelect = (selectedItem) => {
    let merchantList = this.props.merchantIds;
    console.log(selectedItem, "selectedItem")
    this.setState({ selectedMerchants: selectedItem, isMultiSelected: true })
  }

  onSelectBrand = (selectedItem) => { 
    this.setState({ selectedGroupBrands: selectedItem  })
  }

  previewValues = async (values) => {
    let { setPreview } = this.props
    let { summaryValues } = this.state
    values = { ...summaryValues, ...values }
    setPreview(values)
    await this.setState({ previewRoute: true })
  }
  goToCreateCampaign = async () => {
    console.log("Go to Create Campaign  ")
    await this.setState({
      trainComponent: false,
      stepOne: false
    })
  }
  showModal = (location) => {
    const { history } = this.props;
    const { isCampaignCreated } = this.state;

    if (location && !isCampaignCreated) {
      this.setState({
        modalVisible: true,
        lastLocation: location,
      })
    }
  }

  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation, isCampaignCreated } = this.state
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && !isCampaignCreated) {
      this.showModal(nextLocation)
      return false
    }

    return true
  }
  closeModal = () => {
    this.setState({
      modalVisible: false
    })
  }
  handleConfirmNavigationClick = () => {
    const { history } = this.props
    const { lastLocation } = this.state;
    if (lastLocation) {
      this.setState({
        confirmedNavigation: true
      }, () => {
        // Navigate to the previous blocked location with your navigate function     
        history.push(lastLocation.pathname)
      })
    }
  }

  setAllBranchValue = async (allBranch) => {
    if (allBranch) {
      await this.setState({
        allBranch: true
      })
    } else {
      await this.setState({
        allBranch: false
      })
    }


  }

  setDeviceInfo = async () => {
    let deviceInfo = {}
    await publicIp.v4().then((res) => {
      deviceInfo.ipAddress = res
    })
    // macaddress.all().then(async function (all) {
    //  await console.log(JSON.stringify(all, null, 2));
    // });
    deviceInfo.browserName = browserName
    deviceInfo.osName = osName
    deviceInfo.osVersion = osVersion
    deviceInfo.deviceType = deviceType
    this.setState({ deviceInfo: deviceInfo })
  }

  render() {
    let { projections, campaignForEdit, user, settings, brands } = this.props;
    let { sessionExpired, loginRedirect, languageData, isLoading, isFirstLoginModal, branchRoute } = this.state
    let headingLabel = 'Create'
    const { when } = this.props
    const { modalVisible, lastLocation } = this.state
    if (campaignForEdit)
      headingLabel = 'Edit'
    return (
      <div >
        {
          this.state.errorExist ?
            <div className='row errorTextDiv' >
              <div className='col-sm-12 text-center'>
                <h4>{this.state.errorMsgText}</h4>
              </div>
            </div>
            : null}
        <Prompt
          when={when}
          message={this.handleBlockedNavigation}
        />
        < div className='container px-0'>
          {loginRedirect ? <Redirect to='/' /> : null}
          {this.state.previewRoute ? <Redirect to='/user/campaigns/create/preview' /> : null}
          {/* {this.state.trainComponent ? <Redirect to='/traincomponent' /> : null} */}

          {branchRoute ? <Redirect to='/user/branches' /> : null}
          <Loader loader={isLoading} />
          {/* {this.state.trainComponent && <div className='row'>
            <div className='col-sm-12'>
              <h2 className=" w-100 heading">
                <span className='float-right'>
                  <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" />
                </span>
              </h2>
            </div>
          </div>} */}
          {this.state.trainComponent && this.state.brandData && this.state.brandData.name && <div className='row'>
            <div className='col-sm-12'>
              <TrainComponent
                formValues={this.state.formValues}
                submitGuestCampaignData={this.onSubmit}
                goToCreateCampaign={this.goToCreateCampaign}
                setAllBranchValue={this.setAllBranchValue}
                allBranch={this.state.allBranch}
                trainComponentMerchantIds={this.state.trainComponentMerchantIds}
                handleTrainComponentMerchantIds={this.handleTrainComponentMerchantIds}
                brandData={this.state.brandData}
              />
            </div>
          </div>}
          {!this.state.trainComponent && !this.state.stepOne && <div className="create-header">
            <div className="heading">
              <div className="row mb-4">
                <div className="col-md-12 d-lg-flex d-md-flex d-sm-none d-none align-items-center">
                  <h2 className="m-0 w-100">
                    <span>
                      <Icon className="heading-icon">stars</Icon>
                    </span>
                    Create&nbsp;
                    {languageData && languageData['campaign'] ? languageData['campaign'] : 'Campaign'}
                    {/* <span className='float-right'>
                      <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" />
                    </span> */}
                  </h2>
                </div>
                <div className="col-md-12 d-lg-none d-md-none d-sm-block d-block align-items-center">
                  <h2 className="m-0 w-100 ">

                    {/* <span className='float-right'>
                      <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" />
                    </span> */}
                  </h2>
                  <h2 className=" pt-2 m-0 w-100 float-left">
                    <span>
                      <Icon className="heading-icon">stars</Icon>
                    </span>
                    <span>
                      Create&nbsp;
                      {languageData && languageData['campaign'] ? languageData['campaign'] : 'Campaign'}
                    </span>

                  </h2>
                </div>
                {/* {
                user.email === 'brandwithbarclays@test.com'
                  ? (
                    <div style={{ textAlign: 'right' }} className="col-md-4 offset-md-4">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Barclaycard_Logo.svg/1280px-Barclaycard_Logo.svg.png"
                        style={{ width: '150px' }} alt="Barclaycard_Logo" />
                    </div>
                  ) : ''
              } */}
              </div>
            </div>
          </div>}

          {!this.state.trainComponent && !this.state.stepOne && <div className="row">

            <div className="col-lg-6 col-12">
              <div className="card pt-1 mb-0 pb-0 campagnCard">
                <div className="card-body campaignBody">
                  <CampaignForm
                    withoutBranch={true}
                    onChangeListener={this.onChangeListener}
                    formikRef={this.formikRef}
                    setShowTCModal={this.setShowTCModal}
                    initialValues={this.state.campaignFormData}
                    enableReinitialize={true}
                    title={headingLabel}
                    merchantIds={this.state.merchantIds}
                    onRef={(ref) => this.campaignFormRef = ref}
                    formType={this.state.formType}
                    merchantsValid={this.state.selectedMerchants.length ? false : true}
                    documents={this.state.documents}
                    languageData={languageData}
                    onSelect={this.onSelect}
                    merchantList={this.state.selectedMerchants}
                    onSubmit={this.handleAction}
                    weekDaysArray={this.state.weekDaysArray}
                    handleWeekDayArray={this.handleWeekDayArray}
                    settingsState={this.state.settingsState}
                    groupBrandList={this.state.groupBrandList}
                    selectedGroupBrands={this.state.selectedGroupBrands}
                    onSelectBrand={this.onSelectBrand}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 pb-5">
              <CampaignSummary
                withoutBranch={true}
                onRef={(ref) => this.campaignSummaryRef = ref}
                campaignSummary={this.state.campaignFormData}
                onCampaignSummaryData={this.onCampaignSummaryData}
                projections={projections}
                languageData={languageData}
                title={headingLabel}
                setShowTCModal={this.setShowTCModal}
                values={this && this.campaignFormRef && this.campaignFormRef.state && this.campaignFormRef.state.values ? this.campaignFormRef.state.values : {}}
                selectedMerchants={this.state.selectedMerchants}
                totalMerchants={this.state.merchantIds}
                previewValues={this.previewValues}
                onSubmit={this.submitCreateCampaign}
                campaignDelay={settings && settings.campaignOverviewDelay ? settings.campaignOverviewDelay : 5.75}
                isApproved={this.state.isApproved}
                formType={this.state.formType}
                brands={brands}
                selectedDays={this.state.selectedDays}
                brandData={this.state.brandData}
              />

            </div>
          </div>}

          <ModalComponent close={this.onCloseTCModal}
            callback={this.handleTCResponse}
            show={this.state.showTCModal}
            title={""}
            size="lg"
            message={<TnCComponent />}
            action={"Accept"}
          />
          <ModalComponent close={e => this.setState({ branchesPendingModal: false })}
            callback={e => this.setState({ branchRoute: true })}
            show={this.state.branchesPendingModal}
            title={"Message"}
            // size="lg"
            message={<div>
              <h5 className='text-center'>You have pending in branches <span className='text-success font-weight-bold'>Approval</span> / <span className='text-danger font-weight-bold'>Reject</span>,</h5> <h5 className='text-center'>Please <span className='text-success font-weight-bold'>Approval</span> / <span className='text-danger font-weight-bold'>Reject</span> before creating campaign </h5>
            </div>}
            action={"Go to Branches"}

          />
          <SessionExpiryModal
            isOpen={this.state.sessionExpiryModal}
            withoutBrach={true}
          />
          {/* to show no branches modal  */}
          <ModalComponent
            close={e => this.setState({ isFirstLoginModal: false })}
            show={isFirstLoginModal}
            title='Alert'
            message={'You have no Branches to create campaign'}
            action='Add Branch'
            callback={e => this.setState({ branchRoute: true })}
          />
          {/* <ModalComponent
            close={this.closeModal}
            show={modalVisible}
            overrideCancelText='Stay on this page'
            title='Alert'
            message={'Campaign data will be lost if you move away from this page'}
            action='Leave this page'
            callback={this.handleConfirmNavigationClick}
          /> */}
          <ModalComponent
            close={this.closeModal}
            show={modalVisible}
            overrideCancelText='Close'
            title='Alert'
            message={'Please complete the onboarding process in order to move on another page.'}
            // action='Leave this page'
            callback={this.handleConfirmNavigationClick}
          />
        </div>
      </div>

    );
  }
}

export default connect(
  state => ({
    campaignsData: state.campaigns,
    user: state.user,
    campaignForEdit: state.campaigns.campaignForEdit,
    currentCampaignCount: state.campaigns.currentCampaignCount,
    projections: state.selectEntities.projections,
    documents: state.documents.items,
    previewData: state.selectEntities.previewData,
    settings: state.selectEntities.settingsData,
    brands: state.selectEntities.brands,
    branches: state.branches,
  }),
  dispatch => ({
    login: bindActionCreators(loginAction, dispatch),
    loginTrain: bindActionCreators(loginActionForTrainComponent, dispatch),
    loadSettingsData: bindActionCreators(setSettingsData, dispatch),
    loadBrandsData: bindActionCreators(loadBrandData, dispatch),
    getProjectionsForUser: bindActionCreators(getProjectionsForUserActionTrain, dispatch),
    updateUser: bindActionCreators(updateUserProfileAction, dispatch),
    updateUserWithoutBranch: bindActionCreators(updateUserProfileActionWithoutBranch, dispatch),
    saveCsv: bindActionCreators(saveCsvAction, dispatch),
    saveInitialValues: bindActionCreators(saveinitialValuesAction, dispatch),
    createCampaign: bindActionCreators(createCampaignAction, dispatch),
    editCampaign: bindActionCreators(editCampaignAction, dispatch),
    setCampaignForEdit: bindActionCreators(setCampaignForEditAction, dispatch),
    getCurrentCampaignCount: bindActionCreators(getCurrentCampaignCountAction, dispatch),
    getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch),
    getEntityData: bindActionCreators(getEntityAction('createCampaign'), dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    errorNotification: bindActionCreators(errorNotificationAction, dispatch),
    getDetails: bindActionCreators(getEntityDetails, dispatch),
    getCampaignName: bindActionCreators(getCampaignNameAction, dispatch),
    getManageCreative: bindActionCreators(getManageCreativeAction('manageCreative'), dispatch),
    setPreview: bindActionCreators(setPreviewDataAction, dispatch),
    getSettingsData: bindActionCreators(settingsAction, dispatch),
    clearTemplateData: bindActionCreators(clearCampaignTemplateData, dispatch),
    setTabs: bindActionCreators(saveTabData, dispatch),
    loadGuestData: bindActionCreators(loadGuestUserData, dispatch),
    getLanguageData: bindActionCreators(getLanguageDataAction, dispatch),
    getBrandGroupWise: bindActionCreators(getBrandGroupWiseAction, dispatch),
  })
)(CreateCampaign);

