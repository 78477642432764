import config from './config';
// import showToasterMessage from '../containers/UI/ToasterMessage/toasterMessage';
// import apiCalls from './apiCalls';
import axios from 'axios';
import { GetSessionValue, SetSessionValue } from '../utils/sessionStorage';
let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

// To get logged in user token
const getLoggedInuserToken = () => {
    let userData = GetSessionValue('loginCredentials');

    if (userData) {
        headers.Authorization = `Bearer ${userData.accessToken}`;
        headers.logggedInUserData = {
            email: userData.email,
            password: userData.password,
        }
        return headers;
    } else {
        return headers;
    }
}

const axiosMethodRequest = (method, url, body, type) => {

    if (url === 'auth/login') {
        return sendRequestToServer(method, url, body, headers);
    } else {
        let headers = getLoggedInuserToken();
        // upload method conditions, headers
        if (type && type == 'upload') {
            let formData = new FormData();
            // let formData={}
            if (body && body.length && body.length > 0) {
                body.forEach(element => {
                    formData.append('file', element);
                });
            } else if (body) {

                formData.append('file', body);
                // formData.file=body
            }
            if (headers.logggedInUserData) {
                delete headers.logggedInUserData;
            }
            body = {
                isUploadingImage: true,
                imageInfo: formData,
            }
        }

        return sendRequestToServer(method, url, body, headers)
    }
}

const sendRequestToServer = (method, url, body, headers) => {
    let reqHeaders = { ...headers };

    if (reqHeaders && reqHeaders.logggedInUserData) {
        delete reqHeaders.logggedInUserData;
        delete reqHeaders.accept
    }
    let isImageReqSent = false;
    let request;

    if (body && body.isUploadingImage) { // only for image upload
        isImageReqSent = true;
        if (method == 'POST') {
            let modifiedHeaders = {
                // 'accept':'*/*',
                'Accept': '*/*',
                'Authorization': `${reqHeaders.Authorization}`,
            }
            return request = axios.post(`${config.apiUrl}${url}`, body.imageInfo, {
                headers: modifiedHeaders,
            })
        } else if (method == 'PUT') {

        }
        // request = fetch(`${config.apiUrl}${url}`, {
        //     method: method,
        //     headers: {
        //         'Authorization': `${reqHeaders.Authorization}`
        //     },
        //     body: body.imageInfo
        // })

    }

    if (!isImageReqSent) { // send request for call except image upload 
        if (method === 'DELETE') {
            // request = fetch(`${config.apiUrl}${url}`, { method: method, headers: reqHeaders })
            request = axios.delete(`${config.apiUrl}${url}`, {
                headers: reqHeaders,
            })
            return request
        } else if (method === 'GET') {
            request = axios.get(`${config.apiUrl}${url}`, {
                headers: reqHeaders,
            })
            let modifiedRequest = request

            modifiedRequest.then((res) => {
                if (res && res.data && res.data.errorCode && res.data.errorMessage && res.data.errorMessage == "Session expired, please login again.") {
                    SetSessionValue('sessionExpired', true)
                }

            })
            return request
        }
        else if (method === 'PUT') {
            request = axios.put(`${config.apiUrl}${url}`, body, {
                headers: reqHeaders,
            })
            return request
        } else if (method === 'POST') {
            request = axios.post(`${config.apiUrl}${url}`, body, {
                headers: reqHeaders,
            })
            return request
        }
    }


}
export default axiosMethodRequest;
