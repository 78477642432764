import React, { useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
import BranchComponent from './BranchComponent';
import PaymentComponent from './PaymentComponent';
import SetPassword from './SetPassword';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faTimesCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { getLanguageDataAction } from '../../redux/actions';
import { bindActionCreators } from 'redux';
import ModalComponent from '../../components/Modal';

class TrainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentComponent: true,
            branchComponent: false,
            setPassword: false,
            branchComponentProgress: false,
            setPasswordProgress: false,
            processCompleted: true,
            showCloseAMEXPopup: false, // Set to true to show the modal initially
            percentageData: {
                expectedReach: "0",
                expectedConversion: "0",
                expectedRevenue: "0",
                expectedCost: "0",
                cpa: '0'
            },
            prevPercentageData: {
                prevExpectedReach: "0",
                prevExpectedConversion: "0",
                prevExpectedRevenue: "0",
                prevExpectedCost: "0",
                prevCpa: '0'
            },
            termsConditionsDialog: { data: {}, isOpen: false },
            message: ""
        }
    }
    // this.setState({message:`Do you agree to run the Amex phase ${this.state.phase} campaign starting on ${this.state.campaignStartDate} with a ${cashbackPercentage} cashback and ${acquisitionFeePercentage} acquisition fee?`});
    submitGuestCampaignData = async (userData) => {
        await this.setState({
            processCompleted: true
        })
        let values = this.props.formValues;
        values['type'] = 'All';
        values['password'] = userData['password'];
        values['email'] = userData['email'];
        values["entityType"] = "user";
        values['deviceInfo'] = userData['deviceInfo'];
        this.props.submitGuestCampaignData(values)
    }
    submitCreateCampaign = (type, summaryData) => {
        console.log("Submit Create Campaign  :  " + JSON.stringify(type) + "  " + JSON.stringify(summaryData))
        this.setState({ termsConditionsDialog: { data: { type: type, summaryData: summaryData }, isOpen: true } })
    }
    nextActionSetPassword = async () => {
        await this.setState({
            setPassword: true,
            paymentComponent: false,
            branchComponent: false,
            branchComponentProgress: true,
            setPasswordProgress: true,
        })
    }
    backActionToPayment = async () => {
        await this.setState({
            setPassword: false,
            paymentComponent: true,
            branchComponent: false,
            branchComponentProgress: false,
            setPasswordProgress: false,
        })
    }
    payemnetConfirm = async () => {
        await this.setState({
            setPassword: false,
            paymentComponent: false,
            branchComponent: true,
            setPasswordProgress: false,
        })
    }
    payemnetConfirmForProgress = async () => {
        await this.setState({
            setPassword: false,
            paymentComponent: false,
            branchComponent: true,
            branchComponentProgress: true
        })
    }
    skipPayment = async () => {
        await this.setState({
            setPassword: false,
            paymentComponent: false,
            branchComponent: true,
            branchComponentProgress: true
        })
    }
    goToCreateCampaign = async () => {
        this.props.goToCreateCampaign();
    }
    componentDidMount = async () => {
        let { user } = this.props;
        console.log("TrainComponenr Data is    ")
        await this.props.getLanguageData((res) => {
            return res
        })

        if (user && user['stepTwo'] == true) {
            await this.setState({
                paymentComponent: false,
                setPassword: false,
                branchComponent: true,
                branchComponentProgress: true,
                processCompleted: false
            })
        }
        if (user && user['stepThree'] == true && user['stepTwo'] == true) {
            await this.setState({
                branchComponentProgress: true,
                paymentComponent: false,
                branchComponent: false,
                setPassword: true,
                setPasswordProgress: true,
                processCompleted: false
            })
        }
        if (user && user['proceesCompleted'] == true && user['stepThree'] == true && user['stepTwo'] == true) {
            await this.setState({
                branchComponentProgress: true,
                paymentComponent: false,
                branchComponent: false,
                setPassword: true,
                setPasswordProgress: true,
                processCompleted: true
            })
        }
    }

    onOpenCloseAMEXPopup = (type) => {
        if (type == "Close") {
            // dispatch(logoutAction())  
            // setTimeout(() => {
            //   // 👇 Redirects to about page, note the `replace: true`  
            //   this.state.history.push('/login')
            // }, 1000);
        } else {
            this.setState({ showCloseAMEXPopup: false });
        }
    }
    render() {
        const { paymentComponent, setPassword, processCompleted, branchComponent, branchComponentProgress, setPasswordProgress } = this.state;
        const { brandData } = this.props;
        return (
            <Container >
                <Row>

                    <Col sm={12} className='d-flex mt-5 justify-content-center '>
                        <div className='d-block text-center'>
                            <div className='d-flex'>
                                <div className='tabRootFirst' >
                                    <p>
                                        <FontAwesomeIcon icon={faCheck}
                                            className='faCheckClass'
                                        // style={{ color: 'white', fontSize: 20, marginTop: 3 }}
                                        />
                                    </p>
                                </div>
                                <div className='barDiv'>
                                    <div className="progress progressCustom">
                                        <div className="progress-bar progresBarClr" role="progressbar" aria-valuemin="0"
                                            aria-valuemax="100" style={{ width: '100%' }}></div></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-block text-center'>
                            <div className='d-flex'>
                                <div className={paymentComponent ? 'activeDiv' : branchComponent ? 'tabRootFirst' : 'tabRootFirst'}>
                                    {
                                        branchComponentProgress ? <p>
                                            <FontAwesomeIcon icon={faCheck}
                                                className='faCheckClass'
                                            // style={{ color: 'white', fontSize: 20, marginTop: 3 }}
                                            />
                                        </p> : paymentComponent ? <p>
                                            <FontAwesomeIcon icon={faCircle}
                                                className='faCircleClass'
                                            // style={{ color: 'white', fontSize: 12, marginTop: 7 }}
                                            />
                                        </p> : <FontAwesomeIcon icon={faCircle}
                                            className='faExclaClass'
                                        // style={{ color: '#0e4768', fontSize: 12, marginTop: 7 }}
                                        />}

                                </div>
                                <div className='barDiv'>
                                    <div className="progress progressCustom">
                                        <div className="progress-bar progresBarClr" role="progressbar" aria-valuemin="0"
                                            aria-valuemax="100" style={this.state.branchComponentProgress ? { width: '100%' } : { width: '0' }}></div></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-block text-center'>
                            <div className='d-flex'>
                                <div className={branchComponent ? 'activeDiv' : setPassword ? 'tabRootFirst' : 'notVistedDiv'}>
                                    {
                                        setPasswordProgress ? <p>
                                            <FontAwesomeIcon icon={faCheck}
                                                className='faCheckClass'
                                            // style={{ color: 'white', fontSize: 20, marginTop: 3 }}
                                            />
                                        </p> : branchComponent ? <p>
                                            <FontAwesomeIcon icon={faCircle}
                                                className='faCircleClass'
                                            // style={{ color: 'white', fontSize: 12, marginTop: 7 }}
                                            />
                                        </p> : <FontAwesomeIcon icon={faCircle}
                                            className='faExclaClass'
                                        // style={{ color: '#0e4768', fontSize: 12, marginTop: 7 }}
                                        />}
                                </div>
                                <div className='barDiv'>
                                    <div className="progress progressCustom">
                                        <div className="progress-bar progresBarClr" role="progressbar" aria-valuemin="0"
                                            aria-valuemax="100" style={this.state.setPasswordProgress ? { width: '100%' } : { width: '0' }}></div></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-block text-center'>
                            <div className='d-flex'>
                                <div className={setPassword ? 'activeDiv' : 'notVistedDiv'}>
                                    {
                                        processCompleted ? <p>
                                            <FontAwesomeIcon icon={faCheck}
                                                className='faCheckClass'
                                            // style={{ color: 'white', fontSize: 20, marginTop: 5 }}
                                            />
                                        </p> : setPassword ? <p>
                                            <FontAwesomeIcon icon={faCircle}
                                                className='faCircleClass'
                                            // style={{ color: 'white', fontSize: 12, marginTop: 7 }}
                                            />
                                        </p> : <FontAwesomeIcon icon={faCircle}
                                            className='faExclaClass'
                                        // style={{ color: '#0e4768', fontSize: 12, marginTop: 7 }}
                                        />}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} className='d-flex mt-3 justify-content-center '>
                        <div className='headingOfTrain'>
                            <h5 className='adjustFontSizesTrain'><b>Create Campaign</b></h5>
                        </div>
                        <div className='headingOfTrain'>
                            <h5 className='adjustFontSizesTrain'><b>Payment Details</b></h5>
                        </div>
                        <div className='headingOfTrain'>
                            <h5 className='adjustFontSizesTrain'><b>Branch</b></h5>
                        </div>
                        <div className='headingOfTrain'>
                            {/* <h5 className='adjustFontSizesTrain'><b>Set Password</b></h5> */}
                            <h5 className='adjustFontSizesTrain'><b>Account Settings</b></h5>
                        </div>
                    </Col>
                    {this.state.setPassword && <Col sm={12} className='mt-5 px-0'>
                        {/* <div className='headingOfTrain d-lg-none d-md-block pl-2'>
                            <h5 ><b>Set Password</b></h5>
                        </div> */}
                        <SetPassword
                            backAction={this.payemnetConfirm}
                            submitGuestCampaignData={this.submitGuestCampaignData}
                        />
                    </Col>}
                    {this.state.paymentComponent && <Col sm={12} className='mt-5 px-0'>
                        {/* <div className='headingOfTrain d-lg-none d-md-block pl-2'>
                            <h5 ><b>Payment Details</b></h5>
                        </div> */}
                        <PaymentComponent
                            payemnetConfirm={this.payemnetConfirmForProgress}
                            goToCreateCampaign={this.goToCreateCampaign}
                            brandData={brandData}
                            skipPayment={this.skipPayment}
                        />
                    </Col>}
                    {this.state.branchComponent && <Col sm={12} className='mt-5 px-0'>
                        {/* <div className='headingOfTrain d-lg-none d-md-block pl-2'>
                            <h5 ><b>Branch</b></h5>
                        </div> */}
                        <BranchComponent
                            backAction={this.backActionToPayment}
                            nextAction={this.nextActionSetPassword}
                            submitGuestCampaignData={this.submitGuestCampaignData}
                            allBranch={this.props.allBranch}
                            setAllBranchValue={this.props.setAllBranchValue}
                            trainComponentMerchantIds={this.props.trainComponentMerchantIds}
                            handleTrainComponentMerchantIds={this.props.handleTrainComponentMerchantIds}
                        />
                    </Col>}
                    <div className="row justify-content-end pt-1 pb-5 px-lg-4 px-md-0 px-sm-0 px-0">
                        <ModalComponent
                            close={() => { this.onOpenCloseAMEXPopup("Close") }}
                            hideCancelBtn={true}
                            show={this.state.showCloseAMEXPopup}
                            callback={() => { this.onOpenCloseAMEXPopup("Open"); this.submitCreateCampaign() }}
                            title={"We're excited to have you on the Amex Dining Offer! "}
                            // message={"Do you agree to run a 3 month campaign starting in July with a 10% cashback and 5% commission?"}
                            message={`Do you agree to run the Amex phase 3 campaign starting on ${brandData.distributorDealSetWithBrand[0].startDate} with a ${brandData.distributorDealSetWithBrand[0].percentage} cashback and ${brandData.distributorDealSetWithBrand[0].successFee} acquisition fee?`}
                            action={"Yes"}
                        />
                    </div>
                </Row>
            </Container >
        )
    }

}
export default connect(
    state => ({
        user: state.user,
        languageData: state.languages
    }),
    dispatch => ({
        getLanguageData: bindActionCreators(getLanguageDataAction, dispatch),
    })
)(TrainComponent);