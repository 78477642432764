import React from 'react'
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';

const Modal = ({
    close,
    show,
    callback,
    size = "lg",
    centered = true,
    className,
    data
}) => {

    const agreeTermsCondition = () => {
        callback(data.type, data.summaryData);
    }

    return (
        <ModalComponent show={show} onHide={close} size={size} centered={centered} className={className}>
            <ModalComponent.Header closeButton>
                <ModalComponent.Title> <b>Terms & Conditions</b> </ModalComponent.Title>
            </ModalComponent.Header>
            <ModalComponent.Body>

                <div className="row terms-conditions-modal">

                    <div className='col-12 t-c-title'>
                        Krowd Limited Terms and Conditions
                    </div>

                    <div className='col-12 m-t-15'>
                        These terms and conditions govern the access to, and use of, the Marketplace offered by
                        Krowd Limited is a company incorporated in England and Wales with registered number 10027920
                        whose registered address is at 1 Fore Street Avenue, London, EC2Y 9DT (“Krowd”).at www.krowdit.com
                        by the Reward Partner
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>1. Definitions</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        The following words shall have the following meanings within these terms and conditions.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Cashback : </b>  The relevant cashback offered to a Customer in an Offer.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Customer : </b> The customers of the Publisher, to whom the Publisher has provided Offers to.
                    </div>
                    <div className='col-12 m-t-10'>
                        <b>Data Protection Laws</b> means applicable legislation protecting the personal data of natural persons,
                        including the California Consumer Privacy Act (CCPA), any relevant state, federal data or English
                        protection laws, and any binding guidance and codes of practice issued from time to time by relevant supervisory authorities.
                    </div>
                    <div className='col-12 m-t-10'>
                        <b>"GDPR" </b> General Data Protection Regulation (EU) 2016/679, applicable only to the extent it impacts US businesses with EU data subjects.
                    </div>
                    <div className='col-12 m-t-10'>
                        <b>Success Fee : </b> The fee to be paid by the Reward Partner to Krowd for successful Offer Redemptions
                        as submitted by the Reward Partner as part of any Offer submission.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Marketplace : </b> The Krowd customer acquisition marketplace hosted at www.krowdit.com. where the Reward Partner places Offers.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Offer : </b> The cashback offers uploaded by Reward Partners onto the Marketplace for the purchase of
                        certain goods or services from the Reward Partner.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Offer Redemptions : </b> The purchase of goods and services by a Customer that is subject to an Offer.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Publisher : </b> Participating banks, financial partners, airlines, employee benefit platforms and other
                        partners of Krowd who provide Offers to their end customers and users.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Reward Partner : </b> A merchant that collaborates with Krowd to provide offers to Customers.
                    </div>


                    <div className='col-12 m-t-10'>
                        <b>2. Making an Offer Available</b>
                    </div>


                    <div className='col-12 m-t-10'>
                        2.1)  The Reward Partner shall make an Offer available through the Marketplace by completing the Offer
                        submission on the Marketplace which shall include such information as is required for Krowd to host the
                        Offer on the Marketplace, including target Customer group, qualifying offer criteria, total available
                        offer budget as well as upload Reward Partner’s logo and any other images or content as may be reasonably
                        required to enable the hosting of the Offer.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.2) The value of the Offer and the Success Fee is set out in clause 6 of these terms and conditions.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.3) By making an Offer submission, the Reward Partner undertakes to honour such Offer for all and any
                        purchases made by Customers who make an Offer Redemption.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.4) Reward Partner Offer submissions may be amended by the Reward Partner at any point up until 30
                        calendar days before the end of the month prior to the month in which the Offer is due to be run
                        (the <b>“Amendment Period”</b>). Past this point, all amendments shall be at the discretion of Krowd.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.5) Following successful completion of an Offer submission, Krowd shall confirm to the Reward Partner
                        acceptance of the Offer along with relevant details, Krowd reserves the right to not approve any Offer
                        or ask for reasonable amendments prior to such approval.
                    </div>
                    <div className='col-12 m-t-10'>
                        2.6) Krowd shall make the Offer available to a portfolio of Publishers, should the Reward Partner not want
                        the Offer to be made with any specific Publishers then it must notify Krowd via
                        <a className='anchor-tag' href='mail:info@krowdit.com'>info@krowdit.com</a> within
                        the Amendment Period otherwise Krowd shall make the Offer available to all Publishers it deems fit.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.7) Krowd may upload the Merchant IDs to the Marketplace up to 30 days before the Offer goes live in order to review
                        transaction analytics.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>3. Offer Redemption</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        3.1) Where the goods being offered by the Reward Partner have a return period, Krowd shall invoice
                        the Success Fee on completion the applicable return period (up to a maximum of 30 days).
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>4. Data Protection</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        4.1)Krowd only stores the names and email addresses of the Reward Partner’s staff that log onto the
                        Krowd Customer Acquisition Marketplace. Krowd does not store or process additional personal data
                        from Reward Partners or from its Publishers. Examples of non-personal information include,
                        but are not limited to, Customer IDs, Transaction IDs, and Demographic information.
                    </div>

                    <div className='col-12 m-t-10'>
                        4.2) The main purposes for which we use non-personal information are:
                    </div>

                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.1.	Management and administrative purposes of your purchases through Krowd
                    </div>

                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.2.	Providing services tailored to you.
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.3.	Analysis and market research
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.4.	Updates and service communications
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.5.	Transaction monitoring
                    </div>
                    <div className='col-12 m-t-10'>
                        4.3) For the remainder of this clause 4 the expressions "Data Controller", "Data Processor",
                        "Data Subject", “Personal Data”, "Personal Data Breach" and "Process" have the meanings given
                        to them in the Data Protection Laws.
                    </div>
                    <div className='col-12 m-t-10'>
                        4.4) Krowd must duly observe all its obligations under the Data Protection Laws which arise in
                        connection with the provision of the services under the contract created by these terms and
                        conditions and not do anything which puts the Reward Partner in breach of the Data Protection Laws.
                    </div>
                    <div className='col-12 m-t-10'>
                        4.5) Insofar as Krowd Processes Personal Data for the Reward Partner as a Data Processor, Krowd must:
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (i).	process the Personal Data solely on the documented instructions of the Reward Partner,
                        for the purposes of providing the services contemplated by these terms and conditions;
                    </div>

                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ii).	process only the types of Personal Data, relating to the categories of Data Subjects,
                        and in the manner required to deliver the Services, and in the manner agreed by the parties;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (iii).	take all measures required by Article 32 of the GDPR and/or any Policies
                        to ensure the security of the Personal Data;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (iv).	take reasonable steps to ensure the reliability of any staff who may have access to the
                        Personal Data, and their treatment of the Personal Data as confidential;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (v).	not transfer the Personal Data to any country outside the juriisdictions
                        that Krowd operates in without the prior written consent of the Reward Partner;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (vi).	not permit any third party to Process the Personal Data without the prior written
                        consent of the Reward Partner, such consent to be subject to Krowd meeting the conditions set out in Article 28 (2) and (4) of the GDPR;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (vii).	promptly notify the Reward Partner of any communication from a
                        Data Subject regarding the Processing of their Personal Data, or any other communication
                        (including from a supervisory authority) relating to either party's obligations under the Data Protection
                        Laws in respect of the Personal Data;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (viii).immediately upon becoming aware, and in any case within 24 hours,
                        notify the Reward Partner of any Personal Data Breach, such notice to include all information reasonably
                        required by the Reward Partner to comply with its obligations under the Data Protection Laws;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ix).	 permit the Reward Partner, on reasonable prior notice, to inspect and audit
                        the facilities and systems used by Krowd to Process the Personal Data, the technical and organisational measures used
                        by Krowd to ensure the security of the Personal Data and any and all records maintained by Krowd relating to that Processing;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (x).provide any assistance reasonably requested by the Reward Partner in relation to
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (i) any communication received under clause
                        4.5vii, as well as any similar communication received by the Reward Partner directly;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ii) any Personal Data
                        Breach, including by taking any appropriate technical and organisational measures reasonably requested by the Reward Partner;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (xi).	where requested by the Reward Parter, delete any Personal Data in accordance with the Reward Partner’s then current data retention policy; and
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (xii).	cease Processing the Personal Data immediately upon the
                        termination or expiry of this agreement and at the Reward Partner's option either return, or securely
                        delete the Personal Data.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>5. Invoicing and Payment Terms</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        5.1) The Reward Partner shall pay Krowd the Success Fee and Cashback for all Offer Redemptions on a weekly basis. Krowd shall invoice the
                        Reward Partner on a weekly basis through ACH as provided via the GoCardless ACH on the Marketplace.
                        The invoice shall be payable within a calendar month. Late payments shall accrue interest at a rate of 3%
                        above the base rate of the Federal Reserve from time to time.
                    </div>
                    <div className='col-12 m-t-10'>
                        5.2) Krowd undertakes to transfer the Cashback received from the Reward Partner to the relevant Publisher for payment to its Customers,
                        pursuant to its relevant terms and conditions with that Publisher. Furthermore, Krowd agrees to indemnify Reward Partner
                        against any claims or liabilities arising from the failure of a Publisher to adequately compensate its Customers.
                        In the event that a Customer does not receive their due Cashback from the Publisher, Krowd will ensure that the
                        Customer is compensated in full accordance to the terms originally specified, thereby safeguarding the Reward Partner
                        from any associated disputes or financial losses.
                    </div>
                    <div className='col-12 m-t-10'>
                        5.3) Should Krowd receive any data outside of the invoicing period that relates to Offer Redemptions,
                        Krowd shall be entitled to invoice for such Offer Redemptions in the next invoice.
                    </div>



                    <div className='col-12 m-t-10'>
                        <b>6. Success Fee and Cashback Fee</b>
                    </div>
                    <div className='col-12 m-t-10'>
                        6.1) The Success Fee shall be 5% of the value of Offer Redemptions or as submitted via Krowd’s Marketplace.
                    </div>
                    <div className='col-12 m-t-10'>
                        6.2) The Cashback Fee shall be 10% of the value of Offer Redemptions or as submitted via Krowd’s Marketplace.
                    </div>
                    <div className='col-12 m-t-10'>
                        <b>7. Enrolment with Publishers</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        7.1) Krowd will automatically promote the Reward partner to a portfolio of exclusive Publishers that have access to
                        customers. If you do not want to be automatically enrolled, and consequently want to select a Publisher of
                        choice then the brand will need to opt-out.
                    </div>
                    <div className='col-12 m-t-10'>
                        7.2) Such requests should be communicated in writing to Krowd via <a className='anchor-tag' href='mail:info@krowdit.com'>info@krowdit.com</a>
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>8. Removing an Offer</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        8.1) The Offer shall remain on the Marketplace for the duration of the Offer, as selected by the Reward Partner during the Offer submission.
                        The Reward Partner may request for an Offer to end early by providing written notice to Krowd via <a className='anchor-tag' href='mail:info@krowdit.com'>info@krowdit.com</a>.
                        Krowd shall, upon its discretion, remove the Offer by the end of the month following the month in which such notice
                        is given. Notwithstanding the foregoing, Krowd reserves the right to remove an Offer at any time in its complete
                        discretion.
                    </div>


                    <div className='col-12 m-t-10'>
                        <b>9. Krowd’s role as an intermediary </b>
                    </div>
                    <div className='col-12 m-t-10'>
                        9.1) Krowd acts solely as an intermediary, facilitating the presentation of Offers to the Publisher,
                        who in turn makes these available directly to Customers. Each Offer Redemption forms a distinct
                        contract between the Reward Partner and the Customer, for which the Reward Partner is solely responsible.
                        This includes compliance with all applicable laws concerning the provision of goods or services and
                        fulfillment of contracts.
                    </div>
                    <div className='col-12 m-t-10'>
                        The Reward Partner indemnifies Krowd and its Publishers against any losses or claims arising from
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (a). The Reward Partner's failuresto honor Offers.
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (b). Issues related to marketing materials provided to Krowd.
                    </div>
                    <div className='col-12 m-t-10'>
                        Similarly, Krowd shall indemnify, defend and hold harmless the Reward Partner against any losses or claims resulting from
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (a). Krowd's failure to correctly disburse cashbacks as agreed.
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (b). any action demand, claim or allegation that the Reward Partner's use of
                        the Market Place or any other resources provided by or on behalf Krowd infringes any rights of any third party.
                    </div>
                    <div className='col-12 m-t-10'>
                        Each party warrants that it possesses the necessary legal rights to all materials, including marketing content and logos,
                        provided to the other party as part of the Offer's execution.
                    </div>
                    <div className='col-12 m-t-10'>
                        9.2) Krowd is not responsible for Publishers subsequent marketing of the Offer and makes no representations or warranties
                        about the extent to which an Offer is marketed, including whether it is marketed at all. Krowd is not responsible for
                        any failure by a Publisher or itself to make an Offer available.
                    </div>


                    <div className='col-12 m-t-10'>
                        <b>10. License</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        10.1) The Reward Partner grants Krowd and Publishers a revocable, royalty-free, non-exclusive, non- transferable, non-sublicensable
                        licence to use the Reward Partner’s trade marks and other marketing material provided as part of the Offer submission by
                        the Reward Partner through the Marketplace for the purposes of solely making the Offer available and to be used in Krowd’s
                        marketing materials, including Krowd’s website.
                    </div>
                    <div className='col-12 m-t-10'>
                        10.2) Krowd hereby grants to the Reward Partner a worldwide, non-exclusive, Irrevocable licence to use the Marketplace.
                    </div>
                    <div className='col-12 m-t-10'>
                        <b>11. Liability</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        11.1) Both parties agree that nothing in these terms and conditions is intended to exclude or limit the
                        liability of:
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (a). either party for death or personal injury resulting from negligence;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (b). either party for fraud or fraudulent misrepresentation;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (c). either party for any liabilities in anyway that is not permitted under
                        applicable law;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (d). exclude any liabilities that may not be excluded under applicable law; and
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (e). Krowd for any Losses for which it provides and indemnity under the Agreement
                    </div>
                    <div className='col-12 m-t-10'>
                        11.2) Neither party shall be liable to the other in respect of
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (a). any loss of profits or anticipated savings;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (b). any loss of respite or income;
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (c). any loss of business, contracts, or opportunities; or
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (d).  any special, indirect, or consequential loss or damage.
                    </div>


                    <div className='col-12 m-t-10'>
                        <b>12. Miscellaneous</b>
                    </div>
                    <div className='col-12 m-t-10'>
                        12.1) These terms come into effect on an Offer submission and remain in effect until that Offer has been
                        completed.
                    </div>
                    <div className='col-12 m-t-10'>
                        12.2) Both parties shall keep the terms of the agreement and all and any underlying data shared or
                        processed as part of the agreement confidential, save where required to disclose by law.
                    </div>
                    <div className='col-12 m-t-10'>
                        12.3) All notices may be given, in the case of Krowd to the address at the top of these terms and conditions and by
                        email to <a className='anchor-tag' href='mail:info@krowdit.com'>info@krowdit.com</a>, in the case of the Reward Partner, to the address and email provided as part of the Offer submission.
                    </div>
                    <div className='col-12 m-t-10'>
                        12.4) This agreement constitutes the entire agreement between the parties in relation to the subject matter it contains.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>13. Governing law</b>
                    </div>
                    <div className='col-12 m-t-10'>
                        13.1) These terms and conditions and all matters, including non-contractual obligations, arising hereunder
                        shall be governed by the laws of England and Wales and each party irrevocably submits to the exclusive
                        jurisdiction of the courts of England and Wales.
                    </div>

                </div>

            </ModalComponent.Body>
            <ModalComponent.Footer>
                <Button variant="default" onClick={close}>
                    Cancel
                </Button>
                <Button variant="success" onClick={agreeTermsCondition}>
                    Agree
                </Button>
            </ModalComponent.Footer>
        </ModalComponent>
    )
}

export default Modal

